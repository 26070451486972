@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');

*{
  box-sizing: border-box;
}

body{overflow:hidden;}

.App{
  z-index: 1;
  color: #fff;
  font-weight: 700;
  min-height: 100vh;
  position: relative;
  background-color: #000000;
  font-family: "Space Grotesk", sans-serif;
}

.container{
  margin: auto;
  max-width: 100%;
  max-width: 1310px;
  padding-left: 15px;
  padding-right: 15px;
}

.header{
  background-color: #fff;
  padding: 20px 0 10px;
  position: relative;
  z-index: 6;
}

.header-logo p{
  font-weight: 700;
  margin: 4px 0 0;
  font-size: 10px;
  color: #000;
}

.header .container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header img{
  display: block;
}

main{
  padding-top: 5vw;
  padding-bottom: 5vw;
}

.content{
  position: relative;
  max-width: 780px;
  width: 100%;
  z-index: 8;
}

.content h1{
  margin: 0 0 1.3em;
  font-weight: 660;
  font-size: 35px;
}

button, a{
  cursor: pointer;
  outline: none;
}

.btn-white{
  height: 62px;
  border: none;
  color: #000;
  width: 211px;
  font-size: 23px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 8px;
  background: transparent url('./asserts/btn-bg.svg') no-repeat center / cover;
}

.modal-outer{
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
  overflow: auto;
  display: flex;
  padding: 2em;
  height: 100%;
  width: 100%;
  z-index: 9;
  left: 0;
  top: 0;
}

.modal{
  background-color: #fff;
  border-radius: 12px;
  max-width: 490px;
  margin: auto;
  width: 100%;
}

.modal-header{
  border-bottom: 1px solid #D3DAEA;
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  color: #000;
  padding: 16px;
}

.modal-header button{
  top: 16px;
  padding: 0;
  right: 22px;
  border: none;
  background: none;
  position: absolute;
}

.modal-body{
  padding: 35px;
}

.form-group{
  margin-bottom: 20px;
}

.form-group label{
  display: block;
  font-size: 16px;
  color: #485470;
  font-weight: 500;
  margin-bottom: 8px;
}

.form-group input, .form-group textarea{
  border: 1px solid #D3DAEA;
  border-radius: 10px;
  padding: 18px 15px;
  font-weight: 700;
  font-size: 16px;
  outline: none;
  width: 100%;
}

.btn-submit{
  width: 100%;
  border: none;
  height: 56px;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #000000;
}

.wave {
  opacity:0.2;
  bottom: 0px;
  height: 50vh;
  width: 3840px;
  position: absolute;
  transform: translate3d(0, 0, 0);
  animation: wave 4s linear infinite;
  background: url('./asserts/wave.svg') repeat-x center bottom / auto; 
  
}

/* @keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1920px;
  }
} */

.circles{
  position: fixed;
  display: flex;
  z-index: 1;
  right: -30%;
  width: 60%;
  top: 25px;
  bottom: 0;
}

.circles img{
  top: 0;
  left: 0;
  right : 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

.circle-0{
  width: 34.5%;
  animation: rotatefarward 20s linear infinite;
}

@keyframes rotatefarward {
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(360deg);
  }
}

@keyframes rotatebackward {
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(-360deg);
  }
}

.circle-1{
  width: 22.6%;
  animation: rotatebackward 40s linear infinite;
}

.circle-2{
  width: 40.5%;
  animation: rotatefarward 65s linear infinite;
}

.circle-3{
  width: 56.8%;
  animation: rotatebackward 80s linear infinite;
}

.circle-4{
  width: 74.4%;
  animation: rotatefarward 100s linear infinite;
}

.circle-5{
  width: 99.8%;
  animation: rotatebackward 140s linear infinite;
}

.lottie-container{
  align-items: center;
  position: absolute;
  overflow: hidden;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;
}

.lottie-container > div{
  width: 25%;
  pointer-events: none;
}

@media screen and (max-width: 991px) {
  .circles {
    right: -35%;
    width: 70%;
    top: 20%;
  }
}

@media screen and (max-width: 767px) {
  .circles {
    right: -45%;
    width: 90%;
  }

  .modal-header button{
    right: 12px;
  }

  .modal-body {
    padding: 15px;
  }

  .form-group input, .form-group textarea{
    padding: 15px;
  }

  .lottie-container > div, .lottie-container{
    display: none !important;
  }

  .btn-white{
    height: 49px;
    width: 167px;
    font-size: 17px;
    padding-bottom: 5px;
    position: fixed;
    bottom: 20px;
    left: 15px;
  }
}

@media screen and (max-width: 575px) {
  .content h1{
    font-size: 4.8vw;
  }

  .circles {
    right: -65%;
    width: 130%;
  }
}
